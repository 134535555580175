import React from "react";
import "./Navbar.css";

const Navbar = () => {

    return (
        <>
            <header className="header">
                <h1>S.H. Smith Consulting, LLC</h1>
            </header>
        </>
    )
}

export default Navbar;