import logo from './logo.svg';
import './App.css';
import { Routes, Route} from "react-router-dom";
import Root from './components/root/Root';
import Home from './pages/Home';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={ <Root/> }>
          <Route index element={ <Home/> }/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
