import React from "react";
import "./Banner.css";
import MobileBanner from "../../assets/kenny-eliason-zFSo6bnZJTw-unsplash-small.jpg";

const Banner = () => {

    return (
        <>
            
            <div className="banner">
                <img src={MobileBanner} />
                <span>Guided Journeys to Transformation</span>
            </div>
            
        </>
    )
}

export default Banner;