import React from "react";
import WritingBoy from "../../assets/santi-vedri-O5EMzfdxedg-unsplash(1).jpg";
import Classroom1 from "../../assets/pexels-rdne-8364026.jpg";
import Classroom2 from "../../assets/pexels-yankrukov-8618026.jpg";
import Techclass from "../../assets/pexels-a-darmel-7750690(1).jpg";
import DrSmith from '../../assets/original.jpg';
import Grads from '../../assets/rut-miit-YIdkWynJdSk-unsplash-small.jpg';
import "./MainInfo.css"

const MainInfo = () => {

    return (
        <>
            <div className="mission-section">
                <h2 className="mission-header">Our Mission</h2>
                <div className="underline"></div>
                <span className="mission-statement">To provide quality services to our clients that will help their stake holders to prosper and grow.</span>
            </div>
            
            <div className="products-section">
                <div className="text">
                    <h3>Services</h3>
                    <div className="underline black"></div>
                    <p>
                        S.H. Smith Consulting provides educational design services to State Commissioners/Deputies of Education, State Regents; small, medium, and large state and local school districts as well as educational policy think tanks, legislators, research-based organizations; national, state, and local school boards; national, state, and local parent groups; corporate researchers and directors of social responsibility; and to foundations, trusts and other philanthropy groups. Our services include, but are not limited to: instructional design, AI, professional learning, and data-driven custom-designed systems for school improvement, family & community engagement; stakeholder advocacy, clinical administration & supervision, leadership development, parent advocacy, literacy development; educational policy aligned to achievement, RFPs (request for proposals), college and career readiness, and increased graduation rates.
                    </p>
                </div>
                <div className="photos">
                    <img src={Classroom1}/>
                    <img src={WritingBoy}/>
                    <img src={Classroom2}/>
                </div>
            </div>
            
            <div className="customers-section">
                <div className="photos"> 
                    <div className="text">
                        <h3>Customers</h3>
                        <div className="underline black"></div>
                        <p>
                            The target audience for S.H. Smith Consulting is State Departments of Education, School Chancellors, Superintendents & Principals, Directors of Public & Private Charter Schools, For-Profit and Non-Profit Organizations; national, state, and local legislators; Think Tanks, Colleges & Universities; national, state, and city Agencies; also Parent Organizations, Corporate Social Responsibility Directors, Philanthropists, and Advocacy Groups.
                        </p>
                    </div>
                    <img src={Techclass}/> 
                </div>
            </div>

            <div className="grey-background">
                <div className="about-section">
                    <h3 className="about-header">About Us</h3>
                    <div className="underline black"></div>
                    <img src={DrSmith}/>
                    <h4>Shirley H. Smith Ph.D.</h4>
                    <p className="bio">
                        Shirley is an Associate Adjunct Professor of Practice for Science Education/Physics and Chemistry at Hunter College/CUNY and an Education Consultant. She has held faculty and leadership positions at the following colleges and universities: Lehman College, Bronx Community College, Medgar Evers College, Hostos Community College, City College of New York, Borough of Manhattan Community College, SUNY Stony Brook, College of New Rochelle and New York University. Dr. Smith has held senior leadership positions with the New York City Department of Education as school principal, assistance principal, district-wide curriculum specialist/science and mathematics, Central Board Hearing Officer/Office of Appeals & Reviews and a professional developer of principals with in-service assistant principals and teachers. She also served as Deputy Superintendent/Director of Science for the Newark, NJ public schools.
                    </p>
                </div>
                
                <div className="future-section">
                    <img src={Grads} />
                </div>
                
                <footer>
                    <div className="footer-container">
                        <span className="not-for-mobile">Phone - Office: 212.228.9719 / Cell: 917.403.9520</span>
                        
                        <div className="mobile-footer-numbers-container">
                            <span className="mobile-footer-numbers-title">Phone - </span>
                            <div className="mobile-footer-numbers">
                                <span className="mobile-span">Office: 212.228.9719</span>
                                <span className="mobile-span"> Cell: 917.403.9520</span>
                            </div>
                        </div>
                        <span>Email - dr.smith@shsmithconsulting.com</span>
                        <span>Address - 505 LaGuardia Place New York, NY 10012</span>
                    </div>
                </footer>
            </div>
            
        </>
    )
}

export default MainInfo;