import React from "react";
import Navbar from "../components/navbar/Navbar";
import Banner from "../components/banner/Banner";
import MainInfo from "../components/main_info/MainInfo";

const Home = () => {

    return (
        <>
            <Navbar />
            <Banner />
            <MainInfo />
        </>
    )
}

export default Home;